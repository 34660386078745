//sitColorPicker ??????????????????????????????????
.label {
    border: 1px solid #000;
}

.momap-color-picker {
    border: 1px;
    border-color: #969696;
    border-style: solid;
}

.momap-color-picker:hover {
}

.momap-color-picker:focus-within {
    background-color: #ffffff;
    color: #1e1e1e;
    border-color: #3296b9;
    caret-color: #3296b9;
}

.label-property-grid-control-readonly {
    box-sizing: content-box;
    font-size: 11pt;
    font-weight: normal;
    min-height: 30px;
    height: 30px !important;
    background-color: #dcdcdc;
    color: #1e1e1e;
    border-color: transparent;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    padding: 4px;
    min-width: 100%;
    text-align: left;
    max-width: 0;
}

.isrequired .validator-control .ng-touched {
    border-color: #fa142d;
}

.property-grid-input-group {
    width: 100%;
    display: table;
    vertical-align: middle;
    border-collapse: separate;
    position: relative;
}

.property-grid-control {
    box-sizing: content-box;
    font-size: 11pt;
    font-weight: normal;
    min-height: 30px;
    vertical-align: middle;
    width: 100%;
    border: 1px solid;
    background-color: transparent;
    color: #1e1e1e;
    border-color: #aaaaaa;
    padding: 4px;
}

.validator-control,
.validator-control-invalid {
    box-sizing: border-box;
    font-weight: normal;
    vertical-align: middle;
    width: 100%;
    border-width: 4px;
    border-style: solid;
    border-color: #ffffff;
    padding: 4px;
    outline: none;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
    display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
    border-radius: 0;
}
.input-group-addon,
.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}
.input-group-addon {
    box-sizing: content-box;
    //padding: 6px 12px;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    color: #555555;
    text-align: center;
    border: 1px solid #fff;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 2px;
    width: 24px;
    background-color: #fff;
}
.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}
.input-group-addon.input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px;
}
.input-group-addon input[type='radio'],
.input-group-addon input[type='checkbox'] {
  margin-top: 0;
}
.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.input-group-addon:first-child {
    border-right: 0;
}
.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.input-group-addon:last-child {
    border-left: 0;
}
.input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;
}
.input-group-btn > .btn {
    position: relative;
}
.input-group-btn > .btn + .btn {
    margin-left: -1px;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
    z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
    margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
    z-index: 2;
    margin-left: -1px;
}

.dropdown-menu {
    //position: absolute;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    background-clip: padding-box;
}
.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}
.dropdown-menu .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
}
.dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #333333;
    white-space: nowrap;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #337ab7;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    color: #777777;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    background-color: transparent;
    background-image: none;

    cursor: not-allowed;
}
.open > .dropdown-menu {
    display: block;
}
.open > a {
    outline: 0;
}

.opin-mom-ui-hide-command .aw-layout-primaryWorkarea > div > .aw-layout-workareaCommandbar:first-child {
    display: none !important;
}
.opin-mom-ui-hide-command .aw-layout-workareaCommandbar > div > .aw-layout-workareaCommandbar:first-child > .mom-details-advanced-toolbar{
    display: none !important;
}

.aw-widgets-crumbLink {
    color: #ffffff !important;
}