.time-model-container {
    padding-left: 34px;
    width: 100%;
    box-sizing: border-box;
    background-color: white;
}

.time-model-container * {
    box-sizing: border-box;
    font-size: 0;
    position: relative;
}

.time-model-container > .row {
    box-sizing: border-box;
    height: 90px;
    vertical-align: top;
    width: 100%;
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
}

.time-model-container > div:first-child {
    border-top: 1px solid gray;
}

.time-model-container .row .left, .time-model-container .row .right {
    box-sizing: border-box;
    padding: 10px;
    height: 100%;
}

.time-model-container .row .right {
    border-left: 1px solid gray;
}

.time-model-container .detailsContainer {
    width: 66px;
}

.time-model-container .detailsList * {
    width: 100%;
}

.time-model-container .detailsContainer .detailsList {
    top: 40px;
    float: left;
    position: absolute;
    min-width: 200px;
}

.time-model-container .detailsList > ul {
    border-bottom: 1px solid gray;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
}

.detailsList > ul > li {
    font-size: 13px;
    padding: 4px;
    list-style-type: none;
    border-top: 1px solid gray;
}

.time-model-container .delete {
    left: -35px;
    height: 90px;
    top: 0;
    background-color: #ffffff;
    width: 34px;
    position: absolute;
    border-color: #005f87;
    padding: 4px 5px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
}

.time-model-container .change {
    float: right;
}

.time-model-container button {
    width: 24px;
    height: 24px;
    background-color: transparent;
    display: inline;
}

.time-model-container .expand {
    left: 10px;
}

.rotateHalfSpin {
    transform: rotate(180deg);
}

.time-model-container .counter span {
    min-width: 40px;
    line-height: 16px;
    vertical-align: bottom;
    background-color: white;
    border-radius: 11px;
    text-align: center;
    padding: 3px;
    display: inline-block;
    float: left;
}

.time-model-container .counter span::before {
    content: '+';
}

.time-model-container .counter span, .time-model-container .counter span::before {
    font-weight: bold;
}

.time-model-container span, .time-model-container button, .time-model-container .counter * {
    font-size: 13px;
    font-weight: bold;
}

.time-model-container .fixed-size {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    display: block;
    max-height: 3em;
    line-height: calc(1em + 40%);
}

.time-model-container .bottom-blocked {
    position: absolute;
    right: 0;
    bottom: 8px;
    padding: 0 8px 0 8px;
    width: 100%;
}

.time-model-container .dark svg *{
    fill: #1f1f1f;
}

.time-model-container .dark, .time-model-container .dark *{
    color: #1f1f1f;
}

.time-model-container .light svg *{
    fill: white;
}

.time-model-container .delete svg *{
    fill: #005f87;
}

.time-model-container .light, .time-model-container .light *{
    color: white;
}

#btnAdd svg *{
    fill: #005f87;
}

#btnDelete svg *{
    fill: #005f87;
}
