
.aw-commandId-oPINECmdOpenProject {
  border: 0px !important;
}
.aw-commandId-oPINECmdOpenSite {
  border: 0px !important;
}
.aw-commandId-oPINECmdOpenReasonTree {
  border: 0px !important;
}
.ocin-icon-32-inline {
  width: 32px !important;
  display: inline-block !important;
}
.ocin-picker {
  width: calc(100% - 36px) !important; display: inline-flex !important;
}
.ocin-color {
  border:#dcdcdc !important; border-style:solid !important; border-width: 1px !important;
}

.ocin-noFlex {
  height: 26px !important;
  flex: none !important;
}

.ocin-cmdBar {
  min-height: 39px;
  border-top: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 0px 0px;
  margin-bottom: 5px;
  width: 99.9%
}

.ocin-picker-label {
  width: calc(100% - 36px); 
  display: inline-flex !important;
}

.ocin-picker-icon {
  display: inline-flex !important;
  width: 32px;
}

.ocin-guidance {
  margin-bottom:8px !important;
}

.ocin-propspace {
  margin-bottom: 10px;
}

  /* BUG 593870: Background isn't white for MP application */
  .ocin-white {
    background-color: #fff !important;
  }