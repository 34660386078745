// hover:            #daecf0
// pressed:            #afd7e1
// selected:        #cde6eb
// hoverOnSelected:    #a2d0dc

//TODO: Currenty patterns are defined in 'mpGanttOeeDirective.js' file.
//They should be moved out to this css or an external file.
.aw-layout-panelSectionTitle > label {
    text-transform: capitalize !important ;
}

.ocin-statistics-number {
    margin-left: 24px; 
    margin-top: -10px;
}

.ocin-statistics-number-red {
    margin-left: 24px;
    margin-top: -10px;
    background-color:#FA142D !important;
}

.mpLeftIndentation {
    padding: 0 0 0 16px;
}
.littleMargin {
    margin-left: 8px;
    margin-top: 4px;
}
 [mp-gantt-oee-not-in-reason-tree-activity] {
    fill: url(#not-in-reason-tree) !important;
 }

 [mp-gantt-oee-empty-activity] {
    fill: url(#empty-pattern) !important;
 }

 .mp-clickable {
     transition: fill .2s;
 }

.mp-clickable[selected~="selected"], .mp-gantt-selection-marker, .mp-gantt-selection-area {
    fill: #cde6eb !important;
}

.mp-gantt-marker {
    fill: #dc0000 !important;
}

.mp-gantt-selection-area {
    opacity: .75;
}

.mp-clickable:not(.open-interval):hover {
    fill: #daecf0 !important;
}

.mp-clickable[selected~="selected"]:not(.open-interval):hover {
    fill: #a2d0dc !important;
}

.mp-clickable[selected~="selected"]:not(.open-interval):active {
    fill: #afd7e1 !important;
}

/*Common styles - for reason tree popup on stop justificaiton*/
.list-stop-justification-reason-tree  {
    width: 100%;
}
.list-stop-justification-reason-tree .aw-commandIcon .aw-base-icon{
    width: 24px !important;
    height: 24px !important;
}
.aw-splm-tableMenuItemSelected {
    background-color: #afd7e1 !important;
}
.list-stop-justification-reason-tree .aw-commands-svg{
    width: 24px !important;
    height: 24px !important;
}
.list-stop-justification-reason-tree.standardUX .aw-commandIcon .aw-base-icon{
    width: 24px !important;
    height: 24px !important;
}

.list-stop-justification-reason-tree.standardUX .aw-commands-svg{
    width: 24px !important;
    height: 24px !important;
}
.list-stop-justification-reason-tree.shopfloorUX .aw-commands-svg{
    width: 36px !important;
    height: 36px !important;
}
.list-stop-justification-reason-tree .aw-widgets-cellListCellCommands .aw-layout-flexRow{
    display: contents;
}
.list-stop-justification-reason-tree.shopfloorUX .aw-widgets-cellListItemContainer,
.list-stop-justification-reason-tree.shopfloorUX .aw-widgets-cellListItemCell
{
    width: -moz-calc(100% - 26px);
    width: -webkit-calc(100% - 26px);
    width: calc(100% - 26px);
}
.list-stop-justification-reason-tree.shopfloorUX .aw-commandId-mpCmdReasonDrillDown
{
    height: 60px;
    width: 60px;
}
.list-stop-justification-reason-tree.standardUX .aw-widgets-cellListItemContainer,
.list-stop-justification-reason-tree.standardUX .aw-widgets-cellListItemCell
{
    width: -moz-calc(100% - 10px);
    width: -webkit-calc(100% - 10px);
    width: calc(100% - 10px);
}
.list-stop-justification-reason-tree.standardUX .aw-commandId-mpCmdReasonDrillDown{
    height: 40px;
    width: 40px;
}
.stop-justification-widget .aw-layout-panelSectionContent,
.std-stop-justification-widget .aw-layout-panelSectionContent,
.shp-stop-justification-widget .aw-layout-panelSectionContent {
    margin: 0 16px 0 16px;
    max-width: -webkit-fill-available;
    max-width: -moz-available;
}
.stop-justification-reason-tree {
    overflow-y: scroll;
    width: 100%;
    height: 38vh;
}
.std-stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle {
    height: 32px;
    padding: 0 16px;
    margin-bottom: 0.500rem;
}
.shp-stop-justification-widget-mp-popup-label{
    font-size: 1.125rem;
    flex: 0 0 1.5em;
    margin-left: 16px;
}
.stop-justification-widget-mp-popup-label{
    font-size: 0.75rem;
    flex: 0 0 1.5em;
    margin-left: 16px;
}


/*For reason tree popup*/
/* screen size equal to 1024px or more */
@media only screen and (min-device-width: 1367px) {
    .list-stop-justification-reason-tree.automaticUX .aw-commands-svg {
        width: 24px !important;
        height: 24px !important;
    }
    .list-stop-justification-reason-tree.automaticUX .aw-commandId-mpCmdReasonDrillDown{
        height: 40px;
        width: 40px;
    }
    .list-stop-justification-reason-tree.automaticUX .aw-widgets-cellListItemContainer,
    .list-stop-justification-reason-tree.automaticUX .aw-widgets-cellListItemCell{
        width: -moz-calc(100% - 10px);
        width: -webkit-calc(100% - 10px);
        width: calc(100% - 10px);
    }
    .stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle,
    .std-stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle {
        height: 32px;
        padding: 0 16px;
        margin-bottom: 0.500rem;
    }
    .stop-justification-widget .stop-justification-reason-tree,
    .std-stop-justification-widget .stop-justification-reason-tree {
        height: 38vh;
    }

    /* Below are duplicated styles - becasue incase of ShopfloorUX option for any screen size it should apply the shopfloor ux */
    .shp-stop-justification-widget .aw-layout-panelTitle {
        height: 4rem;
    }
    .shp-stop-justification-widget .aw-layout-fxbreadCrumbs{
        height: 3rem;
    }
    .shp-stop-justification-widget .aw-widgets-crumbLink {
        font-size: 1.125rem;
    }
    .shp-stop-justification-widget .aw-popup-contentContainer .aw-layout-panelFooter .aw-base-blk-button{
        min-height: 3rem;
        font-size: 1.350rem;
    }
    .shp-stop-justification-widget .aw-popup-contentContainer .aw-base-blk-button{
        min-height: 3rem;
        font-size: 1.350rem;
    }
    .shp-splm-stop-justification-panelMain .aw-base-iconButton > .aw-base-icon {
        width: 1.5rem;
        height: 1.5rem;
       
    }
    .shp-stop-justification-widget .aw-widgets-propertyValue, .aw-widgets-propertyNonEditValue, .aw-widgets-cellListCellPropertyValue {
        font-size: 1.15rem;
        font-weight: 400;
    }
    .splm-stop-justification-cmd-bar {
        height: 3rem;
    }
    .shp-stop-justification-widget div .aw-layout-eachCrumb .aw-widgets-breadCrumbOverflow {
        font-size: 1.125rem;
    }
    .shp-stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle {
        height: 48px;
        padding: 0 16px;
        margin-bottom: 0.500rem;
    }
    .shp-stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle label {
        font-size: 1.350rem;
    }
    .shp-stop-justification-widget .aw-layout-panelTitle .gwt-Label {
        font-size: 1.620rem;
        font-weight: 600;
    }
    .shp-stop-justification-widget .aw-widgets-cellListCellTitleBlock .aw-widgets-cellListCellTitle {
        font-size: 1.350rem;
    }
    .shp-stop-justification-widget .aw-widgets-cellListCellItemType.aw-base-small {
        font-size: 1.125rem;
        font-weight: normal;
    }
    .shp-stop-justification-widget .aw-widgets-propertyLabelTop {
        font-size: 1.125rem;
    }
    .shp-stop-justification-widget .aw-widgets-propertyLabelTopValueContainer textarea {
        font-size: 1.125rem;
    }        
    .shp-stop-justification-widget .stop-justification-reason-tree {
        height: 27vh;
    }

}
/*For reason tree popup*/
/* screen size equal to 1366px or less */
@media only screen and (max-device-width: 1366px) {
    .list-stop-justification-reason-tree.automaticUX .aw-commands-svg {
        width: 36px !important;
        height: 36px !important;
    }
    .list-stop-justification-reason-tree.automaticUX .aw-commandId-mpCmdReasonDrillDown{
        height: 60px;
        width: 60px;
    }
    .list-stop-justification-reason-tree.automaticUX .aw-widgets-cellListItemContainer,
    .list-stop-justification-reason-tree.automaticUX .aw-widgets-cellListItemCell{
        width: -moz-calc(100% - 26px);
        width: -webkit-calc(100% - 26px);
        width: calc(100% - 26px);
    }
    .stop-justification-widget .aw-layout-panelTitle,
    .shp-stop-justification-widget .aw-layout-panelTitle {
        height: 4rem;
    }
    .stop-justification-widget .aw-layout-fxbreadCrumbs,
    .shp-stop-justification-widget .aw-layout-fxbreadCrumbs {
        height: 3rem;
    }
    .stop-justification-widget .aw-widgets-crumbLink,
    .shp-stop-justification-widget .aw-widgets-crumbLink {
        font-size: 1.125rem;
    }
    .stop-justification-widget .aw-popup-contentContainer .aw-layout-panelFooter .aw-base-blk-button,
    .shp-stop-justification-widget .aw-popup-contentContainer .aw-layout-panelFooter .aw-base-blk-button {
        min-height: 3rem;
        font-size: 1.350rem;
    }
    .stop-justification-widget div .aw-layout-eachCrumb .aw-widgets-breadCrumbOverflow,
    .shp-stop-justification-widget div .aw-layout-eachCrumb .aw-widgets-breadCrumbOverflow {
        font-size: 1.125rem;
    }
    .stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle,
    .shp-stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle {
        height: 48px;
        padding: 0 16px;
        margin-bottom: 0.500rem;
    }
    .stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle label,
    .shp-stop-justification-widget .aw-layout-panelBody .aw-layout-panelSectionTitle label {
        font-size: 1.350rem;
    }
    .stop-justification-widget .aw-layout-panelTitle .gwt-Label,
    .shp-stop-justification-widget .aw-layout-panelTitle .gwt-Label {
        font-size: 1.620rem;
        font-weight: 600;
    }
    .stop-justification-widget .aw-widgets-cellListCellTitleBlock .aw-widgets-cellListCellTitle,
    .shp-stop-justification-widget .aw-widgets-cellListCellTitleBlock .aw-widgets-cellListCellTitle {
        font-size: 1.350rem;
    }
    .stop-justification-widget .aw-widgets-cellListCellItemType.aw-base-small,
    .shp-stop-justification-widget .aw-widgets-cellListCellItemType.aw-base-small {
        font-size: 1.125rem;
        font-weight: normal;
    }
    .stop-justification-widget .aw-widgets-propertyLabelTop,
    .shp-stop-justification-widget .aw-widgets-propertyLabelTop {
        font-size: 1.125rem;
    }
    .stop-justification-widget .aw-widgets-propertyLabelTopValueContainer textarea,
    .shp-stop-justification-widget .aw-widgets-propertyLabelTopValueContainer textarea {
        font-size: 1.125rem;
    }        
    .stop-justification-widget .stop-justification-reason-tree,
    .shp-stop-justification-widget .stop-justification-reason-tree {
        height: 27vh;
    }
}

/*For Splm table*/
/*Common styles for shopfloor ux and standard ux*/
.shp-splm-stop-justification-panelMain{
    padding: 0px !important;
}

.splm-stop-justification-panelMain,
.shp-splm-stop-justification-panelMain,
.std-splm-stop-justification-panelMain {
    height: 100% !important;
}
.splm-stop-justification-panelMain .aw-layout-panelMain .aw-layout-summaryContent,
.shp-splm-stop-justification-panelMain .aw-layout-panelMain .aw-layout-summaryContent,
.std-splm-stop-justification-panelMain .aw-layout-panelMain .aw-layout-summaryContent {
    height: 100% !important;
}
.stop-justification-panelBody {
    height: 100% !important;
    padding: 8px !important;
}
.splm-stop-justification-panelMain .aw-layout-panelMain .aw-base-scrollPanel,
.shp-splm-stop-justification-panelMain .aw-layout-panelMain .aw-base-scrollPanel,
.std-splm-stop-justification-panelMain .aw-layout-panelMain .aw-base-scrollPanel {
    overflow-y: clip;
}

.splm-stop-justification-content-row {
    height: 67% !important;
}
.splm-stop-justification {
    text-align: left;
    height: 100%;
}
.aw-flex-column {
    flex:inherit;
}

.splm-stop-justification .aw-splm-table {
    overflow: inherit;
    max-height: 75vh;
}
.splm-stop-justification-title {
    width: 100%;
    text-align: center;
}
.splm-stop-justification-asset-name {
    width: 100%;
    margin-top: 8px !important;
    text-align: left;
}
.splm-stop-justification-action-btn,
.splm-stop-justification-refresh-btn,
.splm-stop-justification-selectAll-btn,
.splm-stop-justification-unselectAll-btn {
    margin: 0;
}
.splm-stop-justification-footer-col {
    display: inline-table;
    margin: 8px 0 8px 10px;
    text-align: right;
}
.splm-stop-justification-cmd-bar {
    width: auto !important;
}
.textArea-stop-justification-comment,
.textArea-stop-justification-comment textarea {
    width: 100%;
}
.textArea-merge-state-comment,
.textArea-merge-state-comment textarea {
    width: 100%;
}
.textArea-split-state-comment,
.textArea-split-state-comment textarea {
    width: 100%;
}
.panel-merge-comment,
.panel-split-comment,
.panel-justify-comment {
    width: 100%;
}

.panel-edit-comment, 
.textArea-split-state-comment {
    width: 100%;
}

/*For Splm table*/
/* screen size equal to 1024px or more */
/* includes styles for shop floor - this will enable shopfloor ux for any device*/
@media only screen and (min-device-width: 1367px) {
    .shp-splm-stop-justification-panelMain .splm-stop-justification-title {
        font-size: 1.850rem;
    }
    .shp-splm-stop-justification-panelMain .splm-stop-justification-asset-name {
        font-size: 1.850rem;
    }
    .shp-splm-stop-justification-panelMain .aw-base-iconButton > .aw-base-icon {
        width: 1.5rem;
        height: 1.5rem;
       
    }
    .splm-stop-justification-cmd-bar {
        height: 3rem;
    }
    .shp-splm-stop-justification-panelMain .splm-stop-justification-footer-row {
        height: 4rem !important;
    }
    .shp-splm-stop-justification-panelMain .splm-stop-justification-std-btn {
        font-size: 1.350rem !important;
        font-weight: 600 !important;
        min-height: 3rem !important;
    }
    .shp-splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableHeaderRow {
        height: 2.50rem !important;
        min-height: 2rem !important;
    }
    .shp-splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableHeaderCellLabel {
        font-size: 1.125rem !important;
        font-weight: bold !important;
    }
    /*
    .shp-splm-stop-justification-panelMain .splm-stop-justification .afx-checkbox .afx-checkbox-md-style .check {
        width: 18px !important;
        height: 18px !important;
    }
    */
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .ui-grid-row, .shp-splm-stop-justification-panelMain .splm-stop-justification .ui-grid-cell {
        min-height: 48px !important;
        height: 48px !important;
    }

    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableCellText {
        font-size: 1.125rem !important;
        font-weight: normal !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableCanvas {
        min-height: 48px !important;
        height: -webkit-fill-available !important;
        height: -moz-available !important;
    }
    
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableHeaderRow {
        height: 48px !important;
        min-height: 48px !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableHeaderCellLabel {
        font-size: 1.125rem !important;
        font-weight: bold !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-jswidgets-grid .ui-grid-tree-base-row-header-buttons.ui-grid-tree-base-header .aw-base-icon {
        height: 1.2rem !important;
        width: 1.2rem !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table aw-property-lov-val .aw-widget-icon {
        height: 1.2rem !important;
        width: 1.2rem !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table input.aw-jswidgets-choice {
        font-size: 1.125rem !important;
        font-weight: normal !important;
    }
    
    .shp-splm-stop-justification-panelMain .splm-stop-justification .ui-grid-row, 
    .shp-splm-stop-justification-panelMain .splm-stop-justification .ui-grid-cell {
        min-height: 48px !important;
        height: 48px !important;
    }
    .shp-splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableCellText {
        font-size: 1.185rem !important;
        font-weight: normal !important;
    }
    .shp-splm-stop-justification-panelMain .splm-stop-justification .afx-checkbox .afx-checkbox-md-style {
        top: 3px !important;
        left: 3px !important;
    }
    .shp-splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableCanvas {
        min-height: 48px !important;
        height: -webkit-fill-available !important;
        height: -moz-available !important;
    }
}

/* screen size equal to 1366px or less */
@media only screen and (max-device-width: 1366px) {
    .splm-stop-justification-panelMain .splm-stop-justification-title,
    .shp-splm-stop-justification-panelMain .splm-stop-justification-title {
        font-size: 1.850rem;
    }
    .splm-stop-justification-panelMain .splm-stop-justification-asset-name,
    .shp-splm-stop-justification-panelMain .splm-stop-justification-asset-name {
        font-size: 1.850rem;
    }
    .splm-stop-justification-panelMain .splm-stop-justification-footer-row,
    .shp-splm-stop-justification-panelMain .splm-stop-justification-footer-row {
        height: 4rem !important;
    }
    .splm-stop-justification-panelMain .splm-stop-justification-std-btn,
    .shp-splm-stop-justification-panelMain .splm-stop-justification-std-btn {
        font-size: 1.350rem !important;
        font-weight: 600 !important;
        height: 3rem !important;
    }
    .splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableHeaderRow,
    .shp-splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableHeaderRow {
        height: 36px !important;
        min-height: 36px !important;
    }
    .splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableHeaderCellLabel,
    .shp-splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableHeaderCellLabel {
        font-size: 1.125rem !important;
        font-weight: bold !important;
    }
        /*
    .shp-splm-stop-justification-panelMain .splm-stop-justification .afx-checkbox .afx-checkbox-md-style .check {
        width: 18px !important;
        height: 18px !important;
    }
    */
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .ui-grid-row, .shp-splm-stop-justification-panelMain .splm-stop-justification .ui-grid-cell {
        min-height: 48px !important;
        height: 48px !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableCellText {
        font-size: 1.125rem !important;
        font-weight: normal !important;
    }

    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableCanvas {
        min-height: 48px !important;
        height: -webkit-fill-available !important;
        height: -moz-available !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableHeaderRow {
        height: 48px !important;
        min-height: 48px !important;
    }

    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableHeaderCellLabel {
        font-size: 1.125rem !important;
        font-weight: bold !important;
    }
    
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-jswidgets-grid .ui-grid-tree-base-row-header-buttons.ui-grid-tree-base-header .aw-base-icon {
        height: 1.2rem !important;
        width: 1.2rem !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table aw-property-lov-val .aw-widget-icon {
        height: 1.2rem !important;
        width: 1.2rem !important;
    }
    .shp-splm-stop-justification-panelMain .splm-widget-filter-table input.aw-jswidgets-choice {
        font-size: 1.125rem !important;
        font-weight: normal !important;
    }
    
    .splm-stop-justification-panelMain .splm-stop-justification .ui-grid-row, .splm-stop-justification-panelMain .splm-stop-justification .ui-grid-cell,
    .shp-splm-stop-justification-panelMain .splm-stop-justification .ui-grid-row, .shp-splm-stop-justification-panelMain .splm-stop-justification .ui-grid-cell {
        min-height: 48px !important;
        height: 48px !important;
    }
    .splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableCellText,
    .shp-splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableCellText {
        font-size: 1.185rem !important;
        font-weight: normal !important;
    }
    .splm-stop-justification-panelMain .splm-stop-justification .afx-checkbox .afx-checkbox-md-style,
    .shp-splm-stop-justification-panelMain .splm-stop-justification .afx-checkbox .afx-checkbox-md-style {
        top: 3px !important;
        left: 3px !important;
    }
    .splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableCanvas,
    .shp-splm-stop-justification-panelMain .splm-stop-justification .aw-splm-tableCanvas {
        min-height: 48px !important;
        height: -webkit-fill-available !important;
        height: -moz-available !important;
    }
    .shp-time-filter-btn button .aw-base-blk-button:not(.disabled) .aw-accent-highContrast, .aw-widgets-chip:not(.disabled) .aw-accent-highContrast {
        width: 1.125rem !important;
        height: 3.125rem !important;
    }
}


/*For state manual entry widget*/
.button-size-full{
    height:100%;
    width:100%;
}
.state-manual-entry-container{
    align-items: center;
    display: flex;
    justify-content: center;
    padding-left: 8px;
    padding-right: 8px;
}
.shp-btn-insert-manual-state{
    font-size: 1.350rem !important;
    font-weight: 600 !important;
    overflow-wrap: break-word;
    overflow: hidden;
}
.shp-chip-insert-manual-state{
    font-size: 1.350rem !important;
    font-weight: 600 !important;
    height: 3rem !important;
}
.shp-chip-insert-manual-state .aw-widgets-chipLabel{
    height: 3rem !important;
    overflow: hidden;
}
.shp-chip-insert-manual-state .aw-widgets-chipIcon{
    width: 3rem !important;
    height: 3rem !important;
}
.shp-manual-state-label{
    font-size: 1.125rem;
}
.shp-time-filter-label{
    font-size: 1.125rem;
}
.shp-manual-state-chkbox .aw-jswidgets-checkboxLabel {
    font-size: 1.125rem !important;
}
.shp-manual-state-datePicker .aw-widgets-propertyEditValue {
font-size: 1.125rem !important;
}
.shp-manual-state-datePicker .aw-jswidgets-choice {
    font-size: 1.125rem !important;
}
.shp-manual-state-datePicker .aw-widget-icon {
    margin-top: 3px;
}
.shp-manual-state-datePicker .aw-jswidgets-drop .aw-widgets-cellListCellText {
    font-size: 0.95rem !important;
}
.shp-time-filter-label .aw-widgets-propertyEditValue{
    font-size: 1.125rem !important;
}
.shp-time-filter-datePicker .aw-widgets-propertyEditValue {
    font-size: 1.125rem !important;
}
.shp-time-filter-datePicker .aw-jswidgets-choice {
    font-size: 1.125rem !important;
}
.shp-time-filter-datePicker .aw-widget-icon {
    margin-top: 3px;
}
.shp-time-filter-datePicker .aw-widgets-propertyLabel
{
    font-size: 1.125rem !important;
}
.shp-time-filter-datePicker-horizontal .aw-widgets-propertyEditValue {
    font-size: 1.125rem !important;
}
.shp-time-filter-datePicker-horizontal .aw-jswidgets-choice {
    font-size: 1.125rem !important;
}
.shp-time-filter-datePicker-horizontal .aw-widget-icon {
    margin-top: 3px;
}
.shp-time-filter-datePicker-horizontal .aw-widgets-propertyLabel
{
    font-size: 1.125rem !important;
}
.shp-time-filter-toggle-btn .aw-jswidgets-checkboxButton + .aw-jswidgets-toggleLabel {
    width: 42px !important;
    height: 21px !important;
}
.shp-time-filter-toggle-btn .aw-jswidgets-checkboxButton + .aw-jswidgets-toggleLabel:after
{
    width: 16px !important;
    height: 16px !important;
}
.shp-time-filter-toggle-btn .awRoot .aw-widgets-propertyLabelTop {
    font-size: 1.125rem !important;
}
.shp-time-filter-btn{
    height: 3.125rem !important;
    color: #fff !important;
    fill: #fff !important;
    background-color: #005f87 !important;
    border-color: #003951 !important;
    margin-left: 0px !important;
}
.time-filter-btn {
    padding:8px 8px !important;
    color: #fff !important;
    fill: #fff !important;
    background-color: #005f87 !important;
    border-color: #003951 !important;
    margin-left: 0px !important;
}
.time-filter-column {
    display: flex;
    flex-direction: row;
    /* flex: 0 0 3em !important; */
}
.shp-time-filter-column {
    display: flex;
    flex-direction: row;
    flex: 0 0 4em !important;
}
.shp-time-filter-row {
    flex: 0 0 5em !important;
}
.time-filter-row{
    flex: 0 0 4em !important;
}
.time-filter-apply-btn-row {
    margin-bottom: 8px;
    width: 15%;
    align-items: center !important;
    align-self: center !important;
    min-width: 50px;
}
.shp-time-filter-apply-btn-row {
    margin-bottom: 0px;
    width: 15%;
    align-items: center !important;
    align-self: center !important;
    min-width: 60px;
}
.time-filter-column-height{
    flex: 0 0 4em !important;
}
.shp-time-filter-column-height{
    flex: 0 0 6.5em !important;
}
.shp-time-filter-datePicker .aw-jswidgets-dateTimeInputbox .aw-jswidgets-timepicker .aw-widgets-innerWidget input {
    min-width: 110px !important;
    max-width: 220px !important;
}
.time-filter-datePicker .aw-jswidgets-dateTimeInputbox .aw-jswidgets-timepicker .aw-widgets-innerWidget input {
    min-width: 110px !important;
    max-width: 162px !important;
}
.shp-time-filter-datePicker-horizontal .aw-jswidgets-dateTimeInputbox .aw-jswidgets-timepicker .aw-widgets-innerWidget input {
    min-width: 170px !important;
    max-width: 100% !important;
}
.time-filter-datePicker-horizontal .aw-jswidgets-dateTimeInputbox .aw-jswidgets-timepicker .aw-widgets-innerWidget input {
    min-width: 128px !important;
    max-width: 100% !important;
}

/* screen size equal to 1024px or more */
/* includes styles for shop floor - this will enable shopfloor ux for any device*/
@media only screen and (min-device-width: 1367px) {
    .insert-manual-state-reason-tree-full{
        overflow-y: scroll;
        width: 100%;
        height: 40vh;
    }
    .insert-manual-state-reason-tree{
        overflow-y: scroll;
        width: 100%;
        height: 32vh;
    }
    .shp-insert-manual-state-reason-tree-full{
        overflow-y: scroll;
        width: 100%;
        height: 32vh;
    }
    .shp-splm-stop-justification-panelMain .aw-base-iconButton > .aw-base-icon {
        width: 1.5rem;
        height: 1.5rem;
       
    }
    .splm-stop-justification-cmd-bar {
        height: 3rem;
    }
    .shp-insert-manual-state-reason-tree{
        overflow-y: scroll;
        width: 100%;
        height: 24vh;
    }
    .auto-insert-manual-state-reason-tree-full{
        overflow-y: scroll;
        width: 100%;
        height: 40vh;
    }
    .auto-insert-manual-state-reason-tree{
        overflow-y: scroll;
        width: 100%;
        height: 32vh;
    }
    .shp-time-filter-datePicker .aw-widgets-propertyLabelTop{
        font-size: 1.125rem !important;
    }
    .shp-time-filter-datePicker-horizontal .aw-widgets-propertyLabelTop{
        font-size: 1.125rem !important;
    }
    .shp-time-filter-toggle-btn .aw-widgets-propertyLabelTop {
        font-size: 1.125rem !important;
    }

}
/* screen size equal to 1366px or less */
@media only screen and (max-device-width: 1366px) {
    .insert-manual-state-reason-tree-full{
        overflow-y: scroll;
        width: 100%;
        height: 48vh;
    }
    .insert-manual-state-reason-tree{
        overflow-y: scroll;
        width: 100%;
        height: 40vh;
    }
    .shp-insert-manual-state-reason-tree-full{
        overflow-y: scroll;
        width: 100%;
        height: 38vh;
    }
    .shp-insert-manual-state-reason-tree{
        overflow-y: scroll;
        width: 100%;
        height: 28vh;
    }
    .auto-insert-manual-state-reason-tree-full{
        overflow-y: scroll;
        width: 100%;
        height: 38vh;
    }
    .shp-splm-stop-justification-panelMain .aw-base-iconButton > .aw-base-icon {
        width: 1.5rem;
        height: 1.5rem;
    }
    .splm-stop-justification-cmd-bar {
        height: 3rem;
    }
    .auto-insert-manual-state-reason-tree{
        overflow-y: scroll;
        width: 100%;
        height: 28vh;
    }
    .btn-insert-manual-state{
        font-size: 1.350rem !important;
        font-weight: 600 !important;
    }
    .chip-insert-manual-state{
        height: 3rem !important;
        font-size: 1.350rem !important;
        font-weight: 600 !important;
    }
    .chip-insert-manual-state .aw-widgets-chipIcon{
        width: 3rem !important;
        height: 3rem !important;
    }
    .manual-state-label{
        font-size: 1.125rem;
    }
    .manual-state-chkbox .aw-jswidgets-checkboxLabel {
        font-size: 1.125rem !important;
    }
    .manual-state-datePicker .aw-widgets-propertyEditValue {
        font-size: 1.125rem !important;
    }
    .manual-state-datePicker .aw-jswidgets-choice {
        font-size: 1.125rem !important;
    }
    .manual-state-datePicker .aw-jswidgets-drop .aw-widgets-cellListCellText {
        font-size: 0.95rem !important;
    }
    .manual-state-datePicker .aw-widget-icon {
        margin-top: 3px;
    }
    .time-filter-label .aw-widgets-propertyEditValue{
        font-size: 0.75rem !important;
    }
    .time-filter-datePicker .aw-widgets-propertyEditValue {
        font-size: 0.75rem !important;
    }
    .time-filter-datePicker .aw-jswidgets-choice {
        font-size: 0.75rem !important;
    }
    .time-filter-datePicker .aw-widget-icon {
        margin-top: 3px;
    }
    .time-filter-datePicker-horizontal .aw-widgets-propertyEditValue {
        font-size: 0.75rem !important;
    }
    .time-filter-datePicker-horizontal .aw-jswidgets-choice {
        font-size: 0.75rem !important;
    }
    .time-filter-datePicker-horizontal .aw-widget-icon {
        margin-top: 3px;
    }
    .time-filter-label{
        font-size: 0.75rem;
    }
    .time-filter-toggle-btn .aw-jswidgets-checkboxButton + .aw-jswidgets-toggleLabel {
        width: 32px !important;
        height: 16px !important;
    }
    .time-filter-toggle-btn .aw-jswidgets-checkboxButton + .aw-jswidgets-toggleLabel:after{
    width: 12px !important;
    height: 12px !important;
    }
    .time-filter-toggle-btn .aw-widgets-propertyLabelTop {
        font-size: 0.75rem !important;
    }
    .time-filter-datePicker .aw-widgets-propertyLabel
    {
    font-size: 0.75rem !important;
    }
    .time-filter-datePicker-horizontal .aw-widgets-propertyLabel
    {
    font-size: 0.75rem !important;
    }
}

.gantt-parent-asset{
    margin-bottom: 1%;
}

.opin-asset-counter-manual-entry-adapted{
    width:100% !important;
    height:100% !important;
}

.opin-asset-counter-manual-entry{
    width:100% !important;
}


.opin-asset-counters-manual-entry .col-current-state {
    width: 50%;
}

.opin-asset-counters-manual-entry .row-current-state {
    margin-bottom: 1.5em;
}

.opin-asset-counters-manual-entry .chk-auto-datetime {
    position: absolute !important;
    top: 40% !important;
}
.opin-asset-counters-manual-entry .edit-shop-floor {
    height: 28px;
}

.opin-asset-counters-manual-entry .chk-auto-datetime-container {
    margin-left: 10px;
    position: relative;
}
.opin-asset-counters-manual-entry .std-select-fontSize{
    font-size: 100% !important;
}
.opin-asset-counters-manual-entry .aw-widgets-propertyLabelTop{
    font-weight: 700;
}

/*new justification popup styles*/
.shp-stop-justification-widget .sw-sectionTitleContainer{
    height: 48px;
    padding: 0 16px;
    margin-bottom: .5rem;  
}
.shp-stop-justification-widget .sw-sectionTitleContainer .sw-sectionTitle{
    
    font-size: 1.35rem !important;  
}
.shp-stop-justification-widget .aw-widgets-propertyLabelTop,
.shp-stop-justification-widget .sw-property-name,
.shp-stop-justification-widget .aw-ui-tree .aw-ui-treeNode,
.shp-stop-justification-widget .sw-property-val{
    font-size: 1.35rem;
}
.shp-splm-stop-justification-panelMain .sw-button-iconContainer span{
    height: 1.8333333333rem; 
    width: 1.8333333333rem;
}
.opin-asset-counter-rettification-chip{
    width: 100% !important;
    max-width: none !important;
    align-self: center !important;
    cursor: pointer !important;
}
.opin-stacked-gantt-asset-lbl{
    justify-content: center; 
    padding-top: 0.5%; 
    padding-left: 1%;
    overflow-wrap: break-word;
}
.opin-stacked-gantt-asset-lbl {
    justify-content: center; 
    padding-top: 0.5%; 
    padding-left: 1%;
    overflow-wrap: break-word;
}
@for $i from 1 through 100 {
    .opin-stacked-gantt-asset-lbl .fs-#{$i} {
        font-size: #{$i}px;
    }
}
.ocin-asset-state-manual.ocin-no-flex{
    flex:none !important;
}
.ocin-panel-available-states{
    max-height:300px !important;
}


.opin-state-manual-entry{
    width:100% !important;
    max-width: none !important;
}
.composer-asset-state-stacked-gantt-view-opin-widget-text{
    padding: 0 4px;
    border-style: solid;
    border-width: 1px;
    margin: 4px;
}
.composer-asset-state-stacked-gantt-view-opin-widget-text2{
    justify-content: center; 
    padding-top: 0.5%;
    text-align: center;
}

/* time filter style*/
.margin-right-5 {
    margin-right: 5px;
}
margin-right-5-horizontal{
     margin-right: 5px;
     flex: 0 0 5em;
}
.margin-top-10 {
    margin-top: 10px;
}
.margin-8 {
    margin: 8px;
}
.applyDateButton {
    padding:8px 8px;
    width: 90%;
}
.col-current-state {
   width: 100%;
}
.opin-tfw-col-radio-row{
    max-height: 80px;
}
.opin-tfw-col-radio-row2{
    max-height: 80px;
    min-height:50px;
}
