.opinsubmenu-panel-header {
    font-size: 17px !important;
    height: 4em;
    padding: 0px !important;
}

.opinsubmenu-panel-header .aw-panel-caption {
    padding-left: 0px !important;
    padding-top: 0px !important;
    margin-bottom: 12px;
}

.opinsubmenu-link {
    margin-bottom: 8px;
    font-size: 14px;
}