
.aw-widgets-cellListItem {
    padding: 4px;
}
.ocin-notifications-number {
    margin-left: 8px; 
    margin-top: -10px;
}

.opin-notification-margins {
    margin-right: 12px !important;
    margin-left: 8px !important;
}

.opin-notification-cell {
    color: #ffffff;
}
/* Modify Panels width */
#globalNavigationSideNav.aw-sidenav-layoutContainer.aw-sidenav-wide {
    width: 600px;
}

#globalNavigationSideNav.aw-sidenav-layoutContainer.aw-sidenav-standard {
    width: 280px;
}
/* End of Modify Panels width */
.aw-notification-cell {
    background-color: #003750 !important;
    border-color: #828282 !important;
    border-top-width: thin !important;
    box-shadow: 0 4px 8px 0px rgba(120, 120, 120, 0.5) !important;
}
.aw-notification-cell:hover {
    color: #FFFFFF !important;
}
.aw-notification-cell-title-low {
    color: #aae6f5 !important;
}

.aw-notification-cell-prop {
    color: #ffffff !important;
}

.aw-notification-cell-importance-low {
    border-left-color: #aae6f5 !important;
}

.aw-notification-cell-importance-low.aw-notification-cell-read {
    border-left-color: #ffffff !important;
}

.aw-notification-cell-importance-medium {
    border-left-color: #b64c24 !important;
}

.aw-notification-cell-importance-medium.aw-notification-cell-read {
    border-left-color: #ffffff !important;
}

.aw-notification-cell-importance-high {
    border-left-color: #dc0000 !important;
}

.aw-notification-cell-importance-high.aw-notification-cell-read {
    border-left-color: #ffffff !important;
}