.aw-commandId-oPINRCmdOpenDashboardSource {
    border: 0px !important;
}

.aw-commandId-oPINRCmdOpenDashboardPub {
    border: 0px !important;
}
.test-popup-style .aw-panelBody{
    padding: 16px !important;
}
.test-popup-style .sw-component.sw-row {
    align-items:normal !important;
}
.test-popup-style .sw-property-val{
    flex:none !important;
}

.op-in-tree-max-height-24 {
    max-height: 24em;
}
aw-include > div:first-child {
    overflow: auto
}
.aw-sidenav-layoutContainer li.aw-ui-treeNode [name="miscExpandedTree"] svg > .aw-theme-iconOutline {
    fill: inherit;
}
.aw-sidenav-layoutContainer ul li.aw-ui-treeNode {
  margin-left: 16px;
  padding: 2px 0px 2px 0px;
}
.op-in-color-border {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    margin: 0px 1em;
}
.op-in-panel-section {
    margin-bottom: unset !important;
}
.op-in-panel-top-border {
    margin-top: 4px !important;
}
.op-in-tree-max-height-16 {
    max-height: 16em;
}
.aw-jswidgets-timepicker {
    width: 90%;
}
label.sw-property.sw-component.mpLeftIndentation > span.sw-property-name {
    width: 124px !important;
}

.sw-date-container.sw-property-val.ocin-padding-right-left {
    margin-right: .6666666667rem !important;
}

.sw-property-val.sw-row.sw-date-time-container.propertyLabelTopContainer.ocin-no-flex {
    flex: none !important;
}

.opin-ods-kpi-list li{
    background-color: white !important;
    margin-top: unset !important;
    border:none !important;
}
.opin-ods-tab, 
.opin-ods-tab .opin-ods-tab-fix,
.opin-ods-tab > div:nth-child(2){
    height: 100% !important;
}
.opin-ods-warnig-label{
    font-size: medium !important;
}