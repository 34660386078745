.aw-widgets-groupCommand {
    width: auto !important;
}

.aw-command-bar .aw-command .aw-widgets-groupCommand {
    width: auto !important; 
}

.aw-commands-showIconLabel .aw-commands-commandIconButtonText {
    margin-top: 4px !important;
}
.aw-layout-subLocationTitles {
    background-color: transparent !important;
}

.aw-command-bar.aw-commands-commandBarVertical.aw-use-commandOverflow .aw-commands-wrapper {
    padding-top: 0px !important;
}

.ocinPopupStyle {
    padding: 16px !important;
}

span.aw-popup-close {
    color: white !important;
}
button[button-id="Awp0GoBack"] {
    display:none !important;
  }