.asset-metrics .aw-widgets-cellListWidget {
    flex-direction: row;
    flex-wrap: wrap;
}

.asset-metrics .aw-widgets-cellListItem {
    width: 310px;
}

.aw-layout-panelContent #assetListID {
    height: 20vh;
}
.utilsDTFormatSelector {
    min-height: 120px;
}
.aw-layout-panelContent #metricsList {
    height: 40vh;
}
.sites-metrics .aw-widgets-cellListWidget {
    flex-direction: row;
    flex-wrap: wrap;
}

.sites-metrics .aw-widgets-cellListItem {
    width: 310px;
}
.my-panel-section , .my-panel-section summary{
    margin-bottom: 8px !important;
}
.my-panel-section .sw-sectionTitle {
    padding-left: 15px !important;
}
.my-panel-section .sw-section-content{
    max-height: 700px !important;
}
.my-big-panel-section , .my-big-panel-section summary{
    margin-bottom: 8px !important;
}
.my-big-panel-section .sw-sectionTitle {
    padding-left: 15px !important;
}
.my-big-panel-section .sw-section-content{
    max-height: 850px !important;
}
.opin-widget-ktconf-width{
    width: 100% !important;
}
.chart-containerSingleKpi{
    width: 100% !important;
    float: left;
    min-width: 50px;
    min-height: 50px;
    height: 100% !important;
}
.opin-minified-list li{
    max-height: 3.3rem;
}
.opin-minified-list-two li{
    max-height: 4rem;
}
/*SITE AND ASSET FILTER SHP STYLE*/
.site-filter-conf {
    width: 100%;
}
.shp-splm-stop-justification-panelMain .site-filter-conf {
    font-size: large !important;
}
.shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableHeaderCellLabel,
.shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-tableCellText,
.shp-splm-stop-justification-panelMain .splm-widget-filter-list,
.shp-splm-stop-justification-panelMain .splm-widget-filter-list .sw-cell-valName,
.shp-splm-stop-justification-panelMain .splm-widget-filter-list .aw-widgets-cellListCellTitle{
    font-size: large !important;
}
.shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-layout-flexRowContainer{
    height:100% !important;
}
.opin-search-box{
    width:100% !important;
}
.shp-splm-stop-justification-panelMain .opin-search-box{
    font-size: large !important;
}
.shp-splm-stop-justification-panelMain .splm-widget-filter-table .ui-grid-cell{
    align-self: center;
}
.shp-splm-stop-justification-panelMain .site-filter-conf .sw-property-val{
    font-size: large !important;
}
.shp-splm-stop-justification-panelMain .site-filter-conf .aw-widget-icon{
    height: 1.2rem !important;
    width: 1.2rem !important;
}
.opin-qc-editor-hide{
    display:none !important;
}
.shp-splm-stop-justification-panelMain .splm-widget-filter-table .aw-splm-table{
    height:100%;
}
/*SITE AND ASSET FILTER SHP STYLE*/
/*TIME FRAME FILTER SHP STYLE*/
.shp-time-filter-label{
    font-size: large !important;
}
.shp-time-filter-datePicker {
    font-size: large !important;
}
.shp-time-filter-datePicker-horizontal  {
    font-size: large !important;
}
.shp-time-filter-datePicker .sw-property-val{
    font-size: large !important;
}
.shp-time-filter-datePicker .aw-widget-icon{
    height: 1.2rem !important;
    width: 1.2rem !important;
}
.shp-manual-state-label{
    font-size: large !important;
}
.shp-time-filter-toggle-btn {
    font-size: large !important;
}

.fix-time-frame-calendar .sw-date-container input.sw-property-val, .fix-time-frame-calendar .sw-date-container input.sw-property-val:hover, .fix-time-frame-calendar .sw-date-container input.sw-property-val:focus{
    padding-right: unset !important;
    padding-left: unset !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
}
.fix-time-frame-calendar .sw-lov-container input.sw-property-val{
    padding-right: unset !important;
    padding-left: unset !important;
    padding-top: unset !important;
    padding-bottom: unset !important;
}
/*TIME FRAME FILTER SHP STYLE*/
/* .fix-woc-filter-hover .mom-layout-main-view textarea:focus, .fix-woc-filter-hover .mom-layout-main-view textarea:hover, .fix-woc-filter-hover .mom-layout-main-view input:focus, .fix-woc-filter-hover .mom-layout-main-view input:hover, .fix-woc-filter-hover .mom-layout-main-view input[type=text]:focus, .fix-woc-filter-hover .mom-layout-main-view input[type=text]:hover, .fix-woc-filter-hover .mom-layout-main-view input[type=password]:focus, .fix-woc-filter-hover .mom-layout-main-view input[type=password]:hover{
    padding-right: 2rem !important;
} */
.fix-woc-filter-hover .sw-property .sw-lov-container .sw-property-val{
    padding-right: 2rem !important;
}
.fix-high-asset-number-layout1{
    min-height: 70px;
}
.fix-high-asset-number-layout2{
    min-height: 40px;
}