.testclass {
    //background-color: green !important;
    width:100% !important;
    height:100% !important;
}
.opintitlecrumb .aw-widgets-crumbLink{
    font-size: medium !important;
}
.opintitlecrumb .aw-widgets-primaryCrumb{
    font-size: large !important;
    font-weight: bold !important;
}
.sw-column.sw-tabSet.layouttab > div:first-child {
    align-self:center;
}
.sw-column.sw-tabSet.layouttab > div:nth-child(2) {
    height: 100%;
}
.qc-view-containerv{
    max-height:100%
}
.qc-view-containerh{
    max-width:100%
}
.qc-composer-splitter-hidden{
    display:"none" !important;
}
.qc-params-view{
    height:100% !important;
}
.qc-widget-name{
    max-height: 71px;
    margin: 0 0 1.3333333333rem 0;
}
.qc-image-unselected{
    opacity: 0.5;
}
.qc-image-unselected:hover{
    opacity: 1;
}
.qc-image-editorBtn {
    cursor: pointer;
}
.opin-widgets-padding{
    padding:5px;
}
.std-opin-tf-widget-form .opin-widgets-tfw-calendar-column{
    max-height: 60px;
    margin-right: 2px;
}
.shp-opin-tf-widget-form .opin-widgets-tfw-calendar-column{
    max-height: 80px;
    margin-right: 2px;
}
.std-opin-tf-widget-form .opin-widgets-tfw-calendar-column2{
    max-height:110px;
    padding-top: 2px;
}
.shp-opin-tf-widget-form .opin-widgets-tfw-calendar-column2{
    max-height:130px;
    padding-top: 4px;
}
.std-opin-tf-widget-form .opin-widgets-tfw-calendar-column2-pad{
    padding-top: 2px;
}
.shp-opin-tf-widget-form .opin-widgets-tfw-calendar-column2-pad{
    padding-top: 4px;
}
