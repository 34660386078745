.mpsubmenu-panel-header {
    font-size: 17px;
    height: 2em;
}

.mpsubmenu-link {
    margin-bottom: 5px;
}

.aw-commands-commandBarVertical .aw-commands-showIconLabel {
    .aw-commands-commandIconButtonText {
        padding-left: 8px;
    }
}

.sw-sessionControls-header {
    visibility: hidden;
}